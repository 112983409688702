#works{
    margin: 0 auto;
    min-height:  calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}
.workstitle{
    margin: 1rem 0;
    font-size: 3rem;
}
.worksDesc{
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;

}
.worksImgs{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    max-width: 60rem;

}
.worksImgs img {
    max-width: 100%; 
    height: auto; 
}
.worksImg{
    object-fit: cover;
    height: 10rem;
    margin: 0.5rem;
}
.worksBtn{
    margin: 3rem 0 ;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background-color: aliceblue;
    font-size: 1rem;
    
}
.worksBtn:hover{
    background-color:orange;
    color: white;
}
@media  screen and (max-width:585px){
    .worksImg{
        height: 46vw;
        
    }


    
}