.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: auto auto;
}

#intro {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  /* background: linear-gradient(to bottom, #000000, #333538); */
}
.bg {
  position: absolute;
  top: -2rem;
  right: 0;
  left: 1;
  z-index: -1;
  height: 600px;
  width: 400px;
  
}

.introContent {
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.hello {
  font-size: 1.75rem;
  font-weight: 100;
}
.introName {
  color: orange;
}
.introPara {
  font-size: medium;
  font-weight: 100;
}
.btn {
  background: white;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  font-weight: 600;
}
/* .btn :hover {
  color: orange;
} */

@media screen and (max-width: 840px) {
  .bg {
    right: -10vw;
  }
  .introContent {
    font-size: 10vw;
  }
  .hello {
    font-size: 4.5vw;
  }
}

@media screen and (max-width: 480px) {
  .bg {
    right: -20vw;
  }
}
