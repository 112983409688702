.navbar {
  background: black;
  /* background: linear-gradient(to bottom, #000000, #333538); */
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
}
.logo {
  object-fit: cover;
  height: 3rem;
  border-radius: 50px;
}
.desktopMenuListItem {
  margin: 1rem;
  cursor: pointer;
  color: aliceblue;
  /* background-color: antiquewhite; */
}

.desktopMenuListItem:hover {
  color: rgb(255, 157, 0);
  padding-bottom: 0.5rem;
}

.desktopMenuBtn {
  background: white;
  color: black;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 1rem;
  border-radius: 1rem;
  height: 70%;
  cursor: pointer;
}
.desktopMenuBtn:hover {
  background-color: orange; 
  color:#fff;
}

.desktopMenuImg {
  object-fit: cover;
  height: 1rem;
  width: 1rem;
}
.active {
  color: orange;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid orange;
  cursor: pointer;
}
.mobMenu {
  height: 2rem;
  display: none;
  object-fit: cover;
}
.navMenu {
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 14rem;
  background: rgb(40, 40, 40);
  border-radius: 1rem;
}
.listItem {
  color: white;
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background: rgb(30, 30, 30);
}
@media screen and (max-width: 720px) {
  .mobMenu {
    display: flex;
    cursor: pointer;
  }
  .desktopMenu {
    display: none;
  }
  .desktopMenuBtn {
    display: none;
  }
}
