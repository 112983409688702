#skills {
  overflow: hidden;
  width: 90vw;
  max-width: 65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  /* background-color: azure; */
  
}
.skillTitle {
  font-size: 3rem;
  font-weight: 600;
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}
.description {
  font-weight: 400;
  font-size: 1rem;
  max-width: 50rem;
  padding: 0 2rem;
}
.skillBars {
  margin: 1.5rem;
  width: 100vw;
  max-width: 80%;
  text-align: left;
}
.skillBar {
  display: flex;
  margin: 1rem;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  background: rgb(44, 41, 41);
  /* align-items: center;
  padding-left: 60px; */
}
.sillBarImg {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin-right: 2rem;
  border-radius: 50px;
}
.sillBarText > p {
  font-size: 0.9rem;
  font-weight: 200;
}
@media screen and (max-width: 720px) {
  .sillBarText > p {
    font-size: 2vw;
  }
}

@media screen and (max-width: 480px) {
  .sillBarText > p {
    font-size: 3vw;
  }
  .skillBarImg {
    height: 2.25rem;
    width: 2.25rem;
  }
  .skillBarText > h2 {
    font-size: 5rem;
  }
}
