#contactPage {
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactTitle {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.contactDesc {
  padding: 1rem;
  font-size: medium;
  font-weight: 300;
}
.contactForm {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}
.name,
.email,
.message {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 2rem;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 0.5rem;
  background: rgb(40, 40, 40);
  height: 50px;
  padding-left: 12px;
}

.message {
  padding-top: 16px;
}
.error {
  color: red;
}

.submitBtn {
  background: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 3.5rem;
  margin: 2rem;
}
.submitBtn:hover {
  background-color: orange;
  color: white;
}

.links {
  display: flex;
  flex-wrap: wrap;
}
.link {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
  border-radius: 2.5rem;
}
